import React, { useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import Dropzone from "react-dropzone";
import dragimg from "../assets/drag-drop-input.avif";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import { upload } from "../firebase";
import { useID } from "../context/UserProvider";
import { CircularProgress } from "@mui/material";
import Alert from "@mui/material/Alert";
import AddIcon from "@mui/icons-material/Add";

const CustomButton = styled(Button)(({ theme }) => ({
  padding: "10px 25px",
  borderRadius: "10px",
  marginTop: "20px",
}));

const SubmitButton = styled(CustomButton)(({ theme }) => ({
  backgroundColor: "green",
}));

//firebase

export default function HomePageInfo({
  formData,
  setFormData,
  handleChange,
  proceed,
  setProceed,
}) {
  const { submit } = useID();
  const [disabled, setDisabled] = useState(true);
  // const [proceed,setProceed] = useState(false);

  function handleFiles(file, id) {
    upload(file[0], setFormData, formData, id);
  }

  const onImageChange = (e) => {
    const [file] = e.target.files;
    upload(file, setFormData, formData, "clientLogos");
  };

  function delImg(img) {
    setFormData({
      ...formData,
      clientLogos: formData.clientLogos.filter((i) => i !== img),
    });
  }

  useEffect(() => {
    setDisabled(
      !(
        formData.home_image &&
        formData.home_tagLine &&
        formData.home_image &&
        formData.home_title &&
        formData.clientLogos
      )
    );
  }, [formData]);

  return (
    <div className="flex-column">
      <h3>Home Page Info (Preview)</h3>
      {formData.alert && <Alert severity="info">{formData.alert}</Alert>}
      <div className="flex-row">
        <div className="flex-column" style={{ paddingTop: "30px" }}>
          <TextField
            variant="outlined"
            label="Enter Title"
            sx={{ width: "30%", minWidth: "250px" }}
            onChange={(e) => handleChange(e, "home_title")}
            value={formData.home_title}
          />
          <TextField
            variant="outlined"
            label="Enter Sub Title"
            sx={{ width: "30%", minWidth: "250px" }}
            onChange={(e) => handleChange(e, "home_tagLine")}
            value={formData.home_tagLine}
          />
        </div>
        <div>
          <div style={{ paddingBottom: "10px" }}>Cover Image</div>
          <Dropzone
            onDrop={(acceptedFiles) => handleFiles(acceptedFiles, "home_image")}
            value={formData.home_image}
          >
            {({ getRootProps, getInputProps }) => (
              <section className="width-drop">
                <div {...getRootProps()}>
                  <input {...getInputProps()} />
                  <img
                    src={formData["home_image"] || dragimg}
                    alt="drag-input"
                    width={"300px"}
                  ></img>
                </div>
              </section>
            )}
          </Dropzone>
        </div>
      </div>
      {/* firebase */}
      <>Client Logos (Add Minimum 3) This will appear on your website</>
      <div className="flex-row">
        {formData.clientLogos.map((i) => (
          <img src={i} alt="" className="wh-drop" onClick={() => delImg(i)} />
        ))}
        <label htmlFor="file-upload" className="image-adder">
          <AddIcon />
        </label>
        <input id="file-upload" type="file" onChange={onImageChange} />
      </div>
      <div className="flex-row">
        <CustomButton
          variant="contained"
          onClick={(e) => handleChange(e, "activeStep", "-")}
        >
          Prev
        </CustomButton>
        {formData.loader ? (
          <CircularProgress size={30} className="margin-top" />
        ) : (
          <SubmitButton
            variant="contained"
            onClick={() => {
              submit(setFormData, formData);
              setProceed(true);
            }}
            disabled={disabled}
          >
            Submit
          </SubmitButton>
        )}
        {!formData.loader && proceed && (
          <CustomButton
            variant="contained"
            onClick={(e) => handleChange(e, "activeStep", "+")}
          >
            Next
          </CustomButton>
        )}
      </div>
    </div>
  );
}
