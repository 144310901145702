import React, { useEffect, useState } from "react";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import Dropzone from "react-dropzone";
import dragimg from "../assets/drag-drop-input.avif";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import { upload } from "../firebase";
import { CircularProgress } from "@mui/material";
import { useID } from "../context/UserProvider";
import Alert from "@mui/material/Alert";

const CustomButton = styled(Button)(({ theme }) => ({
  padding: "10px 25px",
  borderRadius: "10px",
  marginTop: "20px",
}));

const SubmitButton = styled(CustomButton)(({ theme }) => ({
  backgroundColor: "green",
}));

export default function ThemeDetails({
  formData,
  setFormData,
  handleChange,
  setProceed,
  proceed,
}) {
  const { submit } = useID();
  const [disabled, setDisabled] = useState(true);
  // const [proceed, setProceed] = useState(false);

  function handleFiles(file, id) {
    upload(file[0], setFormData, formData, id);
  }

  useEffect(() => {
    setDisabled(
      !(
        formData.themeId &&
        formData.brandColorPrimary &&
        formData.brandColorSecondry &&
        formData.businessLogo
      )
    );
  }, [formData]);

  return (
    <div className="flex-column">
      <FormControl>
        <h3>Select Your Theme</h3>
        <RadioGroup value={formData.themeId}>
          <div className="flex-row">
            <div className="flex-row">
              <FormControlLabel
                value="1"
                control={<Radio />}
                label="Theme 1"
                onClick={() => setFormData({ ...formData, themeId: "1" })}
              />
              <a
                href="https://www.theme1.thenotary.app/"
                target="_blank"
                rel="noreferrer"
                className="preview-link"
              >
                Preview
              </a>
            </div>
            <FormControlLabel
              value="2"
              control={<Radio />}
              label="Theme 2"
              onClick={() => setFormData({ ...formData, themeId: "2" })}
            />
            <a
              href="https://www.theme2.thenotary.app/"
              target="_blank"
              rel="noreferrer"
              className="preview-link"
            >
              Preview
            </a>
          </div>
          <div className="flex-row">
            <FormControlLabel
              value="3"
              control={<Radio />}
              label="Theme 3"
              onClick={() => setFormData({ ...formData, themeId: "3" })}
            />
            <a
              href="https://www.theme3.thenotary.app/"
              target="_blank"
              rel="noreferrer"
              className="preview-link"
            >
              Preview
            </a>
            <FormControlLabel
              value="4"
              control={<Radio />}
              label="Theme 4"
              onClick={() => setFormData({ ...formData, themeId: "4" })}
            />
            <a
              href="https://www.theme4.thenotary.app/"
              target="_blank"
              rel="noreferrer"
              className="preview-link"
            >
              Preview
            </a>
          </div>
        </RadioGroup>
      </FormControl>
      <div className="flex-row">
        <TextField
          variant="outlined"
          label="Brand Color Primary"
          sx={{ width: "30%", minWidth: "250px" }}
          onChange={(e) => handleChange(e, "brandColorPrimary")}
          value={formData.brandColorPrimary}
        />
        <TextField
          variant="outlined"
          label="Brand Color Secondary"
          sx={{ width: "30%", minWidth: "250px" }}
          onChange={(e) => handleChange(e, "brandColorSecondry")}
          value={formData.brandColorSecondry}
        />
      </div>
      <h3 style={{ fontWeight: "350" }}>Business Logo</h3>
      {/* firebase link */}
      <Dropzone
        onDrop={(acceptedFiles) => handleFiles(acceptedFiles, "businessLogo")}
        value={formData.businessLogo}
      >
        {({ getRootProps, getInputProps }) => (
          <section className="width-drop">
            <div {...getRootProps()}>
              <input {...getInputProps()} />
              <img
                src={formData.businessLogo || dragimg}
                alt="drag-input"
                width={"300px"}
              ></img>
            </div>
          </section>
        )}
      </Dropzone>
      {formData.alert && <Alert severity="info">{formData.alert}</Alert>}
      <div className="flex-row">
        <CustomButton
          variant="contained"
          onClick={(e) => {
            handleChange(e, "activeStep", "-");
          }}
        >
          Prev
        </CustomButton>
        {formData.loader ? (
          <CircularProgress size={30} className="margin-top" />
        ) : (
          <SubmitButton
            variant="contained"
            onClick={() => {
              submit(setFormData, formData);
              setProceed(true);
            }}
            disabled={disabled}
          >
            Submit
          </SubmitButton>
        )}
        {!formData.loader && proceed && (
          <CustomButton
            variant="contained"
            onClick={(e) => handleChange(e, "activeStep", "+")}
            disabled={false}
          >
            Next
          </CustomButton>
        )}
      </div>
    </div>
  );
}
