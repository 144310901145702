import React, { useState } from 'react'
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import {useID} from '../context/UserProvider';
import logo from '../assets/logo.jpg';
import { CircularProgress } from '@mui/material';


function ValidateEmail(mail) 
{
 // eslint-disable-next-line no-useless-escape
 if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(mail))
  {
    return (true)
  }
    alert("You have entered an invalid email address!")
    return (false)
}

const CustomButton = styled(Button)(({ theme }) => ({
    padding : '10px 25px',
    borderRadius : '10px',
    marginTop : '20px'
  }));

export default function CreateEntry({formData, setFormData, handleChange}) {
    const [isValid,setValid] = useState(true);
    const {setID, setEmail} = useID();

    function submitHandler(){
        setFormData({...formData,loader : !!true});
        if(ValidateEmail(formData.companyEmailAddress))
        {
            setValid(true);
            fetch('https://notaryapp-staging.herokuapp.com/onboarding/create-entry-data', 
            {method: 'POST',
            headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json'
            },
             body: JSON.stringify({"email" : formData.companyEmailAddress})})
            .then((res)=>res.json())
            .then((data)=>{
              console.log(data.message === 'email already exists')
                if(data.message === 'email already exists')
                {
                  setEmail(formData.companyEmailAddress,setFormData,formData,true);
                  setFormData({...formData,loader : !!false});
                }
                else
                {
                  setID(data['_id'],setFormData,formData);
                  setFormData({...formData,loader : !!false});
                }
            });
        }
        else
        {
            setValid(false);
            setFormData({...formData,loader : !!false});
        }
    }

  return (
    <div className='form-container center'>
    <img src={logo} alt='logo' width={"200px"}></img>
    <strong className='margin-b'>Welcome to Notary App</strong>
    <span className='margin-b'>Get started by Providing your email</span>
    <TextField variant="outlined" label="Company Email" 
    onChange={(e)=>handleChange(e,'companyEmailAddress')} 
    value={formData.companyEmailAddress}
    error = {!isValid} 
    helperText={!isValid && "Invalid Email"}
    />
    {formData.loader? <CircularProgress size={30} className='margin-top'/> :<CustomButton variant="contained" onClick={submitHandler}>Submit</CustomButton>}
    </div>
  )
}
