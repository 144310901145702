import './App.css';
import Form from './components/Form'
import { UserProvider } from './context/UserProvider';
import { createTheme, ThemeProvider } from '@mui/material';
// import WhitneyBook from './fonts/WhitneyBook.otf';

// const WhitneyBookFont = {
//   fontFamily: 'WhitneyBook',
//   src: `
//     local('WhitneyBook'),
//     url(${WhitneyBook}) format('opentype')
//   `,
// };

const theme = createTheme({
  palette: {
    primary: {
      // light: will be calculated from palette.primary.main,
      main: '#4C78F3',
      // dark: will be calculated from palette.primary.main,
      // contrastText: will be calculated to contrast with palette.primary.main
    }
  },
  typography: {
    fontFamily: 'Whitney'
  },
  overrides: {
    MuiCssBaseline: {
      '@global': {
        '@font-face': ['Whitney'],
      },
    },
  },
});

function App() {
  return (
    <div>
      <UserProvider>
        <ThemeProvider theme={theme}>
          <Form/>
        </ThemeProvider>
      </UserProvider>
    </div>
  );
}

export default App;
