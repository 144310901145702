// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getStorage, ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBfBWuFs6-iG6-1j6dDn475Qv1XkiwZIWw",
  authDomain: "the-notary-app.firebaseapp.com",
  projectId: "the-notary-app",
  storageBucket: "the-notary-app.appspot.com",
  messagingSenderId: "237405059009",
  appId: "1:237405059009:web:dffeeae95b26a7dedca140",
  measurementId: "G-C4VMT313S2"
};
 
// Initialize Firebase
const app = initializeApp(firebaseConfig);
const storage = getStorage(app);

export async function upload(file,setFormData,formData,id,obj){
    let d = new Date();
    let uid = '';
    uid += d.getTime()+Math.random(100);
    uid.replace('.','@');
    const storageRef = ref(storage,'onboarding-files/'+uid);
    const uploadTask = uploadBytesResumable(storageRef, file);
    uploadTask.on('state_changed',
  (snapshot) => {
    // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
    const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
    setFormData({...formData,alert:'Upload is ' + Math.round(progress) + '% done'})
  }, 
  (error) => {
    // A full list of error codes is available at
    // https://firebase.google.com/docs/storage/web/handle-errors
    console.log(error);
  }, 
  () => {
    // Upload completed successfully, now we can get the download URL
    getDownloadURL(uploadTask.snapshot.ref).then((url)=>{
      if(id === "credentials")
      {
        setFormData({...formData, "credentials" : formData.credentials.concat({"title" : obj.title, "link": url })})
      }
      else if(id === "ProfessionalPhotos")
      {
        setFormData({...formData, "ProfessionalPhotos" : formData.ProfessionalPhotos.concat(url)});
      }
      else if(id === "clientLogos")
      {
        setFormData({...formData, "clientLogos" : formData.clientLogos.concat(url)});
      }
      else if(id === "testimonial")
      {
        setFormData({...formData, "image" : url});
      }
      else
      {
        setFormData({...formData, [id] : url });
      }
})
  }
);
}