import React, { useState, useEffect } from "react";
import AboutPage from "./AboutPage";
import ContactPage from "./ContactPage";
import HomePageInfo from "./HomePageInfo";
import PageNames from "./PageNames";
import PersonalDetails from "./PersonalDetails";
import ServicesPage from "./ServicesPage";
import StepperComponent from "./StepperComponent";
import TestimonialPage from "./TestimonialPage";
import ThemeDetails from "./ThemeDetails";
import { useID } from "../context/UserProvider";
import CreateEntry from "./CreateEntry";

function FormStep({ formData, setFormData, handleChange }) {
  const [proceed, setProceed] = useState(false);
  const [themeProceed, setThemeProceed] = useState(false);
  const [pageProceed, setPageProceed] = useState(false);
  const [homeProceed, setHomeProceed] = useState(false);
  const [contactProceed, setContactProceed] = useState(false);
  const [serviceProceed, setServiceProceed] = useState(false);
  const [aboutProceed, setAboutProceed] = useState(false);

  if (formData.activeStep === 0) {
    return (
      <PersonalDetails
        formData={formData}
        setFormData={setFormData}
        handleChange={handleChange}
        setProceed={setProceed}
        proceed={proceed}
      />
    );
  } else if (formData.activeStep === 1) {
    return (
      <ThemeDetails
        formData={formData}
        setFormData={setFormData}
        handleChange={handleChange}
        setProceed={setThemeProceed}
        proceed={themeProceed}
      />
    );
  } else if (formData.activeStep === 2) {
    return (
      <PageNames
        formData={formData}
        setFormData={setFormData}
        handleChange={handleChange}
        proceed={pageProceed}
        setProceed={setPageProceed}
      />
    );
  } else if (formData.activeStep === 3) {
    return (
      <HomePageInfo
        formData={formData}
        setFormData={setFormData}
        handleChange={handleChange}
        proceed={homeProceed}
        setProceed={setHomeProceed}
      />
    );
  } else if (formData.activeStep === 4) {
    return (
      <ContactPage
        formData={formData}
        setFormData={setFormData}
        handleChange={handleChange}
        proceed={contactProceed}
        setProceed={setContactProceed}
      />
    );
  } else if (formData.activeStep === 5) {
    return (
      <ServicesPage
        formData={formData}
        setFormData={setFormData}
        handleChange={handleChange}
        proceed={serviceProceed}
        setProceed={setServiceProceed}
      />
    );
  } else if (formData.activeStep === 6) {
    return (
      <AboutPage
        formData={formData}
        setFormData={setFormData}
        handleChange={handleChange}
        proceed={aboutProceed}
        setProceed={setAboutProceed}
      />
    );
  } else {
    return (
      <TestimonialPage
        formData={formData}
        setFormData={setFormData}
        handleChange={handleChange}
      />
    );
  }
}

export default function Form() {
  const { setEmail } = useID();
  const [url] = useState(window.location.href);

  useEffect(() => {
    setEmail(url, setFormData, formData);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [url]);

  const [formData, setFormData] = useState({
    alert: "",
    loader: false,
    activeStep: -1,
    firstName: "",
    lastName: "",
    personalEmailAddress: "",
    companyEmailAddress: "",
    personalPhoneNumber: "",
    companyPhoneNumber: "",
    isWebsiteAlreadyPresent: "",
    websiteName: "",
    isDomainPurchased: "",
    domainName: "",
    themeId: "",
    businessName: "",
    brandColorPrimary: "",
    brandColorSecondry: "",
    businessLogo: "",
    navMenu_serviceName: "",
    navMenu_pricingName: "",
    navMenu_aboutMe: "",
    navMenu_BookName: "",
    schedulePage: "",
    navMenu_testimonialName: "",
    navMenu_contact: "",
    home_title: "",
    home_tagLine: "",
    home_image: "",
    clientLogos: [],
    businessFax: "",
    businessAddress: "",
    socialMediaLinks: "",
    servicesOffered: [
      {
        id: 1,
        serviceName: "Real Estate Signing (In Person)",
        serviceDescription: "",
        price: "",
      },
      {
        id: 2,
        serviceName: "General Online Notarization",
        serviceDescription: "",
        price: "",
      },
      {
        id: 3,
        serviceName: "Online Real Estate Notarization",
        serviceDescription: "",
        price: "",
      },
    ],
    showPricing: true,
    ProfessionalPhotos: [],
    bio: "",
    credentials: [],
    testimonial: [],
  });

  function handleChange(e, key, val = false) {
    if (key === "businessLogo" || key === "coverImage") {
      setFormData({ ...formData, [key]: e[0].name }); //change this to file data later
      return;
    }
    if (val) {
      if (val === "+") {
        let newVal = formData.activeStep + 1;
        setFormData({ ...formData, [key]: newVal });
      } else {
        let newVal = formData.activeStep - 1;
        setFormData({ ...formData, [key]: newVal });
      }
    } else {
      setFormData({ ...formData, [key]: e.target.value });
    }
  }

  return (
    <div className="form-parent">
      {formData.activeStep === -1 ? (
        <CreateEntry
          formData={formData}
          setFormData={setFormData}
          handleChange={handleChange}
        />
      ) : (
        <>
          <div className="stepper">
            <StepperComponent activeStep={formData.activeStep} />
          </div>
          <div className="form-container">
            <FormStep
              formData={formData}
              setFormData={setFormData}
              handleChange={handleChange}
            />
          </div>
        </>
      )}
    </div>
  );
}
