import React, { useState, useEffect } from "react";
import Button from "@mui/material/Button";
//import FormGroup from '@mui/material/FormGroup';
//import FormControlLabel from '@mui/material/FormControlLabel';
import TextField from "@mui/material/TextField";
//import Checkbox from '@mui/material/Checkbox';
import { styled } from "@mui/material/styles";
//import EditIcon from '@mui/icons-material/Edit';
//import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from "@mui/icons-material/Close";
import { CircularProgress } from "@mui/material";
import { useID } from "../context/UserProvider";

const CustomButton = styled(Button)(({ theme }) => ({
  padding: "10px 25px",
  borderRadius: "10px",
  marginTop: "20px",
}));

const SubmitButton = styled(CustomButton)(({ theme }) => ({
  backgroundColor: "green",
}));

const CustomButton2 = styled(Button)(({ theme }) => ({
  maxWidth: "200px",
}));

export default function ServicesPage({
  formData,
  setFormData,
  handleChange,
  proceed,
  setProceed,
}) {
  const { submit } = useID();

  function editService(e, ser, property) {
    let services = [...formData.servicesOffered];
    let service = formData.servicesOffered.filter((s) => s.id === ser.id)[0];
    service[property] = e.target.value;
    services = services.map((s) => {
      if (s.id === service.id) {
        return service;
      } else {
        return s;
      }
    });
    setFormData({ ...formData, servicesOffered: services });
  }

  function deleteService(ser) {
    let updatedServices = formData.servicesOffered.filter(
      (s) => s.serviceName !== ser.serviceName
    );
    setFormData({ ...formData, servicesOffered: updatedServices });
  }

  function addService(id) {
    let newService = [
      {
        id: id,
        serviceName: "",
        serviceDescription: "",
        price: "",
      },
    ];
    setFormData({
      ...formData,
      servicesOffered: formData.servicesOffered.concat(newService),
    });
  }

  const [disabled, setDisabled] = useState(true);
  // const [proceed, setProceed] = useState(false);

  useEffect(() => {
    setDisabled(
      !(
        formData.servicesOffered.length &&
        formData.servicesOffered[0].serviceName
      )
    );
  }, [formData]);

  return (
    <div className="flex-column">
      <h3>
        Select Your Services that you perform and it's pricing (This will be
        listed on your Services Page)
      </h3>
      {formData.servicesOffered.map((ser, i) => {
        return (
          <div className="flex-row margin-b">
            <TextField
              variant="outlined"
              label="Service Name"
              sx={{ width: "30%", minWidth: "240px" }}
              value={ser.serviceName}
              size="small"
              onChange={(e) => editService(e, ser, "serviceName")}
            />
            <TextField
              variant="outlined"
              label="Description"
              sx={{ width: "30%", minWidth: "240px" }}
              value={ser.serviceDescription}
              size="small"
              onChange={(e) => editService(e, ser, "serviceDescription")}
            />
            <TextField
              variant="outlined"
              label="Price"
              sx={{ width: "20%", minWidth: "150px" }}
              value={ser.price}
              size="small"
              type="number"
              onChange={(e) => editService(e, ser, "price")}
            />
            <CloseIcon
              className="edit-icon"
              onClick={() => deleteService(ser)}
            />
          </div>
        );
      })}
      <CustomButton2
        variant="outlined"
        size="small"
        onClick={() => addService(formData.servicesOffered.length + 3)}
      >
        Add Custom Service
      </CustomButton2>
      <div className="flex-row">
        <CustomButton
          variant="contained"
          onClick={(e) => handleChange(e, "activeStep", "-")}
          disabled={false}
        >
          Prev
        </CustomButton>
        {formData.loader ? (
          <CircularProgress size={30} className="margin-top" />
        ) : (
          <SubmitButton
            variant="contained"
            onClick={() => {
              submit(setFormData, formData);
              setProceed(true);
            }}
            disabled={disabled}
          >
            Submit
          </SubmitButton>
        )}
        {!formData.loader && proceed && (
          <CustomButton
            variant="contained"
            onClick={(e) => handleChange(e, "activeStep", "+")}
            disabled={false}
          >
            Next
          </CustomButton>
        )}
      </div>
    </div>
  );
}
