import React, { useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import { CircularProgress } from "@mui/material";
import { useID } from "../context/UserProvider";

const CustomButton = styled(Button)(({ theme }) => ({
  padding: "10px 25px",
  borderRadius: "10px",
  marginTop: "20px",
}));

const SubmitButton = styled(CustomButton)(({ theme }) => ({
  backgroundColor: "green",
}));

export default function PageNames({
  formData,
  setFormData,
  handleChange,
  proceed,
  setProceed,
}) {
  const { submit } = useID();
  const [disabled, setDisabled] = useState(true);
  // const [proceed,setProceed] = useState(false);

  useEffect(() => {
    setDisabled(
      !(
        formData.navMenu_serviceName &&
        formData.navMenu_BookName &&
        formData.navMenu_aboutMe &&
        formData.navMenu_contact &&
        formData.navMenu_testimonialName &&
        formData.navMenu_pricingName
      )
    );
  }, [formData]);

  return (
    <div className="flex-column">
      <h3>Page Names and Text</h3>
      <div className="flex-row">
        <TextField
          value={formData.navMenu_serviceName}
          onChange={(e) => handleChange(e, "navMenu_serviceName")}
          sx={{ width: "30%", minWidth: "250px" }}
          select // tell TextField to render select
          label="Service Page Name"
        >
          <MenuItem key={1} value="My Services">
            My Services
          </MenuItem>
          <MenuItem key={2} value="Services">
            Services
          </MenuItem>
          <MenuItem key={3} value="Services Offered">
            Services Offered
          </MenuItem>
        </TextField>
        <TextField
          value={formData.navMenu_pricingName}
          onChange={(e) => handleChange(e, "navMenu_pricingName")}
          sx={{ width: "30%", minWidth: "250px" }}
          select // tell TextField to render select
          label="Pricing Page Name"
        >
          <MenuItem key={1} value="Pricing">
            Pricing
          </MenuItem>
          <MenuItem key={2} value="Rates">
            Rates
          </MenuItem>
          <MenuItem key={3} value="Notary Fees">
            Notary Fees
          </MenuItem>
        </TextField>
      </div>
      <div className="flex-row">
        <TextField
          value={formData.navMenu_aboutMe}
          onChange={(e) => handleChange(e, "navMenu_aboutMe")}
          sx={{ width: "30%", minWidth: "250px" }}
          select // tell TextField to render select
          label="About Me Page Name"
        >
          <MenuItem key={1} value="Notary Bio">
            Notary Bio
          </MenuItem>
          <MenuItem key={2} value="Notary Resume">
            Notary Resume
          </MenuItem>
          <MenuItem key={3} value="About Me">
            About Me
          </MenuItem>
        </TextField>
        <TextField
          value={formData.navMenu_BookName}
          onChange={(e) => handleChange(e, "navMenu_BookName")}
          sx={{ width: "30%", minWidth: "250px" }}
          select // tell TextField to render select
          label="Scheduling Page Name"
        >
          <MenuItem key={1} value="Schedule Now">
            Schedule Now
          </MenuItem>
          <MenuItem key={2} value="Booking">
            Booking
          </MenuItem>
          <MenuItem key={3} value="Book Me">
            Book Me
          </MenuItem>
          <MenuItem key={4} value="Appointments">
            Appointments
          </MenuItem>
        </TextField>
      </div>
      <div className="flex-row">
        <TextField
          value={formData.navMenu_testimonialName}
          onChange={(e) => handleChange(e, "navMenu_testimonialName")}
          sx={{ width: "30%", minWidth: "250px" }}
          select // tell TextField to render select
          label="Testimonial Section Name"
        >
          <MenuItem key={1} value="Reviews">
            Reviews
          </MenuItem>
          <MenuItem key={2} value="Testimonials">
            Testimonials
          </MenuItem>
        </TextField>
        <TextField
          value={formData.navMenu_contact}
          onChange={(e) => handleChange(e, "navMenu_contact")}
          sx={{ width: "30%", minWidth: "250px" }}
          select // tell TextField to render select
          label="Contact Page Name"
        >
          <MenuItem key={1} value="Contact">
            Contact
          </MenuItem>
          <MenuItem key={2} value="Contact Info">
            Contact Info
          </MenuItem>
          <MenuItem key={3} value="Contact me">
            Contact me
          </MenuItem>
        </TextField>
      </div>
      <div className="flex-row">
        <CustomButton
          variant="contained"
          onClick={(e) => handleChange(e, "activeStep", "-")}
        >
          Prev
        </CustomButton>
        {formData.loader ? (
          <CircularProgress size={30} className="margin-top" />
        ) : (
          <SubmitButton
            variant="contained"
            onClick={() => {
              submit(setFormData, formData);
              setProceed(true);
            }}
            disabled={disabled}
          >
            Submit
          </SubmitButton>
        )}
        {!formData.loader && proceed && (
          <CustomButton
            variant="contained"
            onClick={(e) => handleChange(e, "activeStep", "+")}
            disabled={false}
          >
            Next
          </CustomButton>
        )}
      </div>
    </div>
  );
}
