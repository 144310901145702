import React, { useEffect, useRef, useState } from "react";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import { useID } from "../context/UserProvider";
import { CircularProgress } from "@mui/material";

const CustomButton = styled(Button)(({ theme }) => ({
  padding: "10px 25px",
  borderRadius: "10px",
  marginTop: "20px",
}));

const SubmitButton = styled(CustomButton)(({ theme }) => ({
  backgroundColor: "green",
}));

export default function PersonalDetails({
  formData,
  setFormData,
  handleChange,
  setProceed,
  proceed,
}) {
  const { submit } = useID();
  const [disabled, setDisabled] = useState(true);
  // const [proceed, setProceed] = useState(false);
  useEffect(() => {
    setDisabled(
      !(
        formData.firstName &&
        formData.lastName &&
        formData.businessName &&
        formData.personalEmailAddress &&
        formData.personalPhoneNumber &&
        formData.isWebsiteAlreadyPresent &&
        formData.websiteName &&
        formData.isDomainPurchased
      )
    );
  }, [formData]);

  return (
    <div className="flex-column">
      <h3>Personal Details</h3>
      <div className="flex-row">
        <TextField
          variant="outlined"
          label="First Name"
          sx={{ width: "30%", minWidth: "250px" }}
          onChange={(e) => handleChange(e, "firstName")}
          value={formData.firstName}
        />
        <TextField
          variant="outlined"
          label="Last Name"
          sx={{ width: "30%", minWidth: "250px" }}
          onChange={(e) => handleChange(e, "lastName")}
          value={formData.lastName}
        />
      </div>
      <TextField
        variant="outlined"
        label="Business Name"
        sx={{ width: "30%", minWidth: "250px" }}
        onChange={(e) => handleChange(e, "businessName")}
        value={formData.businessName}
      />
      <div className="flex-row">
        <TextField
          variant="outlined"
          label="Email"
          sx={{ width: "30%", minWidth: "250px" }}
          onChange={(e) => handleChange(e, "personalEmailAddress")}
          helperText="Please ensure, you're signing up with the NNA Email"
          value={formData.personalEmailAddress}
        />
        <TextField
          variant="outlined"
          label="Phone"
          sx={{ width: "30%", minWidth: "250px" }}
          onChange={(e) => handleChange(e, "personalPhoneNumber")}
          value={formData.personalPhoneNumber}
        />
      </div>
      <TextField
        value={formData.isWebsiteAlreadyPresent}
        onChange={(e) => handleChange(e, "isWebsiteAlreadyPresent")}
        sx={{ width: "30%", minWidth: "250px" }}
        select // tell TextField to render select
        label="Do you have a website?"
      >
        <MenuItem key={1} value="yes">
          Yes
        </MenuItem>
        <MenuItem key={2} value="no">
          No
        </MenuItem>
      </TextField>
      {formData.isWebsiteAlreadyPresent === "yes" && (
        <TextField
          variant="outlined"
          label="Website Name"
          sx={{ width: "30%", minWidth: "250px" }}
          value={formData.websiteName}
          onChange={(e) => handleChange(e, "websiteName")}
        />
      )}
      <TextField
        value={formData.isDomainPurchased}
        onChange={(e) => handleChange(e, "isDomainPurchased")}
        sx={{ width: "30%", minWidth: "250px" }}
        select // tell TextField to render select
        label="Do you have a Domain?"
      >
        <MenuItem key={1} value="yes">
          Yes
        </MenuItem>
        <MenuItem key={2} value="no">
          No
        </MenuItem>
      </TextField>
      {formData.isDomainPurchased === "yes" && (
        <div className="flex-row">
          <TextField
            variant="outlined"
            label="Domain Name"
            sx={{ width: "30%", minWidth: "250px" }}
            onChange={(e) => handleChange(e, "domainName")}
            value={formData.domainName}
          />
        </div>
      )}
      <div className="flex-row">
        {formData.loader ? (
          <CircularProgress size={30} className="margin-top" />
        ) : (
          <SubmitButton
            variant="contained"
            onClick={() => {
              submit(setFormData, formData);
              setProceed(true);
            }}
            disabled={disabled}
          >
            Submit
          </SubmitButton>
        )}
        {!formData.loader && proceed && (
          <CustomButton
            variant="contained"
            onClick={(e) => {
              handleChange(e, "activeStep", "+");
            }}
          >
            Next
          </CustomButton>
        )}
      </div>
    </div>
  );
}
