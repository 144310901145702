import React, { useState, useEffect } from "react";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import CloseIcon from "@mui/icons-material/Close";
import { upload } from "../firebase";
import { CircularProgress } from "@mui/material";
import { useID } from "../context/UserProvider";
import Alert from "@mui/material/Alert";

const CustomButton = styled(Button)(({ theme }) => ({
  padding: "10px 25px",
  borderRadius: "10px",
  marginTop: "20px",
}));

const SubmitButton = styled(CustomButton)(({ theme }) => ({
  backgroundColor: "green",
}));

const CustomButton2 = styled(Button)(({ theme }) => ({
  padding: "10px 25px",
  borderRadius: "10px",
  marginTop: "20px",
  maxWidth: "200px",
}));

export default function TestimonialPage({
  formData,
  setFormData,
  handleChange,
}) {
  const [data, setData] = useState({
    name: "",
    title: "",
    image: "",
    text: "",
  });

  const { submit } = useID();

  const onImageChange = (e) => {
    const [file] = e.target.files;
    upload(file, setData, data, "testimonial");
  };

  function delTestimonial(tes) {
    setFormData({
      ...formData,
      testimonial: formData.testimonial.filter((t) => t.text !== tes.text),
    });
  }

  const addTestimonial = () => {
    setFormData({
      ...formData,
      testimonial: formData.testimonial.concat({
        name: data.name,
        title: data.title,
        image: data.image,
        text: data.text,
      }),
    });
    setData({
      name: "",
      title: "",
      image: "",
      text: "",
    });
    console.log(formData);
  };

  const [disabled, setDisabled] = useState(true);

  useEffect(() => {
    setDisabled(
      !(
        formData.testimonial.length &&
        formData.testimonial[0].name &&
        formData.testimonial[0].title &&
        formData.testimonial[0].text
      )
    );
  }, [formData]);

  return (
    <div className="flex-column">
      <h3>Testimonials</h3>
      <>Add minimum 3 to maximum of 7</>
      {formData.alert && <Alert severity="info">{formData.alert}</Alert>}
      <div className="flex-row">
        <TextField
          variant="outlined"
          label="Person Name"
          sx={{ width: "30%", minWidth: "250px" }}
          onChange={(e) => setData({ ...data, name: e.target.value })}
          value={data.name}
          size="small"
        />
        <TextField
          variant="outlined"
          label="Designation/Title"
          sx={{ width: "30%", minWidth: "250px" }}
          onChange={(e) => setData({ ...data, title: e.target.value })}
          value={data.title}
          size="small"
        />
        <label htmlFor="file-upload" className="upload-btn">
          Image
        </label>
        {data.image && <div className="upload-btn upload-status">Uploaded</div>}
        <input id="file-upload" type="file" onChange={onImageChange} />
      </div>
      <TextField
        variant="outlined"
        label="Review/Testimonial Text"
        sx={{ width: "75%", minWidth: "250px" }}
        onChange={(e) => setData({ ...data, text: e.target.value })}
        value={data.text}
        multiline
        minRows={4}
      />
      <CustomButton2 variant="outlined" onClick={addTestimonial}>
        Add
      </CustomButton2>
      {formData.testimonial.map((tes) => {
        return (
          <div className="flex-row test-map-item">
            <div>
              {tes.name} : {tes.text}
            </div>
            <CloseIcon
              onClick={() => {
                delTestimonial(tes);
              }}
            />
          </div>
        );
      })}
      <div className="flex-row">
        <CustomButton
          variant="contained"
          onClick={(e) => handleChange(e, "activeStep", "-")}
        >
          Prev
        </CustomButton>
        {formData.loader ? (
          <CircularProgress size={30} className="margin-top" />
        ) : (
          <SubmitButton
            variant="contained"
            onClick={() => submit(setFormData, formData)}
            disabled={disabled}
          >
            Submit
          </SubmitButton>
        )}
      </div>
    </div>
  );
}
