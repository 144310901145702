import React, { useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import InstagramIcon from "@mui/icons-material/Instagram";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import { CircularProgress } from "@mui/material";
import { useID } from "../context/UserProvider";

const CustomButton = styled(Button)(({ theme }) => ({
  padding: "10px 25px",
  borderRadius: "10px",
  marginTop: "20px",
}));

const SubmitButton = styled(CustomButton)(({ theme }) => ({
  backgroundColor: "green",
}));

export default function ContactPage({
  formData,
  setFormData,
  handleChange,
  proceed,
  setProceed,
}) {
  const { submit } = useID();
  const [disabled, setDisabled] = useState(true);
  // const [proceed, setProceed] = useState(false);

  useEffect(() => {
    setDisabled(
      !(
        formData.companyEmailAddress &&
        formData.businessAddress &&
        formData.businessFax &&
        formData.businessPhone
      )
    );
  }, [formData]);

  return (
    <div className="flex-column">
      <h3>Contact Page (Preview) </h3>
      <div className="flex-row">
        <TextField
          variant="outlined"
          label="Business Email"
          sx={{ width: "30%", minWidth: "250px" }}
          onChange={(e) => handleChange(e, "companyEmailAddress")}
          value={formData.companyEmailAddress}
        />
        <TextField
          variant="outlined"
          label="Business Phone"
          sx={{ width: "30%", minWidth: "250px" }}
          onChange={(e) => handleChange(e, "businessPhone")}
          value={formData.businessPhone}
        />
      </div>
      <div className="flex-row">
        <div className="flex-column">
          <TextField
            variant="outlined"
            label="Business Fax"
            sx={{ width: "30%", minWidth: "250px" }}
            onChange={(e) => handleChange(e, "businessFax")}
            value={formData.businessFax}
          />
          <TextField
            variant="outlined"
            label="Business Address"
            sx={{ width: "30%", minWidth: "250px" }}
            onChange={(e) => handleChange(e, "businessAddress")}
            value={formData.businessAddress}
            multiline
            minRows={4}
          />
        </div>
        <div className="flex-column socials">
          <div className="flex-row">
            <FacebookIcon fontSize="large" />
            <TextField
              variant="outlined"
              label="@"
              sx={{ width: "30%", minWidth: "240px" }}
              onChange={(e) => handleChange(e, "facebook")}
              value={formData.facebook}
              size="small"
            />
          </div>
          <div className="flex-row">
            <TwitterIcon fontSize="large" />
            <TextField
              variant="outlined"
              label="@"
              sx={{ width: "30%", minWidth: "240px" }}
              onChange={(e) => handleChange(e, "twitter")}
              value={formData.twitter}
              size="small"
            />
          </div>
          <div className="flex-row">
            <LinkedInIcon fontSize="large" />
            <TextField
              variant="outlined"
              label="@"
              sx={{ width: "30%", minWidth: "240px" }}
              onChange={(e) => handleChange(e, "linkedin")}
              value={formData.linkedin}
              size="small"
            />
          </div>
          <div className="flex-row">
            <InstagramIcon fontSize="large" />
            <TextField
              variant="outlined"
              label="@"
              sx={{ width: "30%", minWidth: "240px" }}
              onChange={(e) => handleChange(e, "instagram")}
              value={formData.instagram}
              size="small"
            />
          </div>
        </div>
      </div>
      <div className="flex-row">
        <CustomButton
          variant="contained"
          onClick={(e) => handleChange(e, "activeStep", "-")}
          disabled={false}
        >
          Prev
        </CustomButton>
        {formData.loader ? (
          <CircularProgress size={30} className="margin-top" />
        ) : (
          <SubmitButton
            variant="contained"
            onClick={() => {
              submit(setFormData, formData);
              setProceed(true);
            }}
            disabled={disabled}
          >
            Submit
          </SubmitButton>
        )}
        {!formData.loader && proceed && (
          <CustomButton
            variant="contained"
            onClick={(e) => handleChange(e, "activeStep", "+")}
            disabled={false}
          >
            Next
          </CustomButton>
        )}
      </div>
    </div>
  );
}
