import React, { useContext } from "react";

const UserContext = React.createContext();

const allowed = [
  "firstName",
  "lastName",
  "personalEmailAddress",
  "companyEmailAddress",
  "websiteStagingLink",
  "websiteApproved",
  "personalPhoneNumber",
  "companyPhoneNumber",
  "businessName",
  "businessFax",
  "businessPhone",
  "businessAddress",
  "state",
  "city",
  "isWebsiteAlreadyPresent",
  "websiteName",
  "isDomainPurchased",
  "domainName",
  "themeId",
  "brandColorPrimary",
  "brandColorSecondry",
  "businessLogo",
  "navigationMenuServiceName",
  "navMenu_serviceName",
  "navMenu_BookName",
  "navMenu_pricingName",
  "navMenu_aboutMe",
  "navMenu_contact",
  "navMenu_testimonialName",
  "home_image",
  "home_title",
  "home_tagLine",
  "clientLogos",
  "testimonial",
  "socialMediaLinks",
  "servicesOffered",
  "publicProfileLink",
  "ProfessionalPhotos",
  "credentials",
  "trainingExperience",
  "bio",
  "AdditionalNotes",
  "status",
  "activityLog",
];

function ValidateEmail(mail) {
  // eslint-disable-next-line no-useless-escape
  if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(mail)) {
    return true;
  }
  return false;
}

export function useID() {
  return useContext(UserContext);
}

export function UserProvider({ children }) {
  function setEmail(url, setFormData, formData, isEmail) {
    let email = "";
    if (!isEmail) {
      let baseUrl = "http://localhost:3000/";
      if (url.slice(0, baseUrl.length) === "http://localhost:3000/") {
        console.log("hello");
      } else {
        baseUrl = "https://onboarding-notary.netlify.app/";
      }
      email = url.slice(baseUrl.length + 1);
      console.log(email);
    } else {
      email = url;
    }

    if (ValidateEmail(email)) {
      fetch(
        "https://notaryapp-staging.herokuapp.com/onboarding/get-entry-data-by-email",
        {
          method: "POST",
          headers: {
            Accept: "application/json, text/plain, */*",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ email: email }),
        }
      )
        .then((res) => res.json())
        .then((data) => {
          const res = data.entryData;
          console.log(res);
          setFormData({ ...formData, ...res, activeStep: 0 });
        });
    } else {
      setFormData({ ...formData, activeStep: -1 });
    }
  }

  function setID(id, setFormData, formData) {
    fetch(
      "https://notaryapp-staging.herokuapp.com/onboarding/get-entry-data-by-id",
      {
        method: "POST",
        headers: {
          Accept: "application/json, text/plain, */*",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ _id: id }),
      }
    )
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        const res = data.entryData;
        console.log(res);
        setFormData({
          ...formData,
          ...res,
          activeStep: 0,
          servicesOffered: [
            {
              id: 1,
              serviceName: "Real Estate Signing (In Person)",
              serviceDescription: "",
              price: "",
            },
            {
              id: 2,
              serviceName: "General Online Notarization",
              serviceDescription: "",
              price: "",
            },
            {
              id: 3,
              serviceName: "Online Real Estate Notarization",
              serviceDescription: "",
              price: "",
            },
          ],
        });
        setEmail(formData.companyEmailAddress, setFormData, formData, true);
      });
  }

  function submit(setFormData, formData) {
    const filteredData = Object.keys(formData)
      .filter((key) => allowed.includes(key))
      .reduce((obj, key) => {
        obj[key] = formData[key];
        return obj;
      }, {});

    console.log({ _id: formData._id, dataObject: filteredData });

    setFormData({ ...formData, loader: !!true });

    console.log(formData.loader);

    fetch(
      "https://notaryapp-staging.herokuapp.com/onboarding/update-entry-data",
      {
        method: "POST",
        headers: {
          Accept: "application/json, text/plain, */*",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ _id: formData._id, dataObject: filteredData }),
      }
    )
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        setFormData({ ...formData, loader: !!false });
      });
  }

  const value = {
    setEmail,
    setID,
    submit,
  };

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
}
