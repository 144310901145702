import React from 'react'
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';

const steps = ["Personal Details","Theme",
"Page Names","Home Page Information","Contact Page","Services","About","Testimonials"];

export default function StepperComponent({activeStep}) {
  return (
    <Stepper activeStep={activeStep} orientation="vertical">
    {steps.map((step,index)=>(
      <Step key={step}>
        <span className='step-label'>{step}</span>
        <StepLabel></StepLabel>
      </Step>
    ))}
    </Stepper>
  )
}
