import React, { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import CloseIcon from "@mui/icons-material/Close";
import { upload } from "../firebase";
import AddIcon from "@mui/icons-material/Add";
import { CircularProgress } from "@mui/material";
import { useID } from "../context/UserProvider";
import Alert from "@mui/material/Alert";

const CustomButton = styled(Button)(({ theme }) => ({
  padding: "10px 25px",
  borderRadius: "10px",
  marginTop: "20px",
}));

const SubmitButton = styled(CustomButton)(({ theme }) => ({
  backgroundColor: "green",
}));

export default function AboutPage({
  formData,
  setFormData,
  handleChange,
  proceed,
  setProceed,
}) {
  const [credName, setCredName] = useState("");
  const [credentials] = useState([]);
  const { submit } = useID();

  const [disabled, setDisabled] = useState(true);
  // const [proceed, setProceed] = useState(false);

  useEffect(() => {
    setDisabled(
      !(
        formData.credentials.length &&
        formData.ProfessionalPhotos.length &&
        formData.bio
      )
    );
  }, [formData]);

  const onImageChange = (e) => {
    const [image] = e.target.files;
    upload(image, setFormData, formData, "ProfessionalPhotos");
  };

  const onResumeChange = async (e) => {
    let [resume] = e.target.files;
    upload(resume, setFormData, formData, "credentials", { title: "Resume" });
  };

  const onCredChange = (e) => {
    const [crednew] = e.target.files;
    upload(crednew, setFormData, formData, "credentials", { title: credName });
  };

  function del(link) {
    setFormData({
      ...formData,
      credentials: credentials.filter((i) => i.link !== link),
    });
  }

  function delImg(img) {
    setFormData({
      ...formData,
      ProfessionalPhotos: formData.ProfessionalPhotos.filter((i) => i !== img),
    });
  }

  function delRes() {
    setFormData({
      ...formData,
      credentials: formData.credentials.filter((c) => c.title !== "Resume"),
    });
  }

  return (
    <div className="flex-column">
      <h3>About Me Page</h3>
      {formData.alert && <Alert severity="info">{formData.alert}</Alert>}
      <>
        Add Your Professional Photos,{" "}
        {formData.ProfessionalPhotos.length !== 0 && "Click to delete"}
      </>
      <div className="flex-row">
        {formData.ProfessionalPhotos.map((i) => (
          <img src={i} alt="" className="wh-drop" onClick={() => delImg(i)} />
        ))}
        <label htmlFor="file-upload" className="image-adder">
          <AddIcon />
        </label>
        <input id="file-upload" type="file" onChange={onImageChange} />
      </div>
      <TextField
        variant="outlined"
        label="Your Bio"
        sx={{ width: "40%", minWidth: "250px" }}
        onChange={(e) => handleChange(e, "bio")}
        value={formData.bio}
        multiline
        minRows={4}
      />
      <div className="flex-row">
        <div style={{ padding: "5px 25px" }}>Resume</div>
        <label htmlFor="file-upload-2" className="upload-btn">
          Upload
        </label>
        <input id="file-upload-2" type="file" onChange={onResumeChange} />
        {formData.credentials.filter((c) => c.title === "Resume").length ===
          1 && (
          <>
            <div className="upload-btn upload-status">Uploaded</div>
            <CloseIcon onClick={() => delRes()} />
          </>
        )}
      </div>
      <>Your Credentials</>
      <div className="flex-row">
        <TextField
          variant="outlined"
          label="Type"
          sx={{ width: "30%", minWidth: "250px" }}
          onChange={(e) => setCredName(e.target.value)}
          value={credName}
          size="small"
        />
        <label htmlFor="file-upload-3" className="upload-btn">
          Upload
        </label>
        <input id="file-upload-3" type="file" onChange={onCredChange} />
      </div>
      {formData.credentials.map((cred) => {
        return (
          <div className="flex-row">
            <div style={{ padding: "5px 25px" }}>{cred.title}</div>
            <div className="upload-btn upload-status">
              <a
                href={cred.link}
                style={{ "text-decoration": "none", color: "white" }}
                target="_blank"
                rel="noreferrer"
              >
                Uploaded
              </a>
            </div>
            <CloseIcon onClick={() => del(cred.link)} className="cross-icon" />
          </div>
        );
      })}
      <div className="flex-row">
        <CustomButton
          variant="contained"
          onClick={(e) => handleChange(e, "activeStep", "-")}
        >
          Prev
        </CustomButton>
        {formData.loader ? (
          <CircularProgress size={30} className="margin-top" />
        ) : (
          <SubmitButton
            variant="contained"
            onClick={() => {
              submit(setFormData, formData);
              setProceed(true);
            }}
            disabled={disabled}
          >
            Submit
          </SubmitButton>
        )}
        {!formData.loader && proceed && (
          <CustomButton
            variant="contained"
            onClick={(e) => handleChange(e, "activeStep", "+")}
            disabled={false}
          >
            Next
          </CustomButton>
        )}
      </div>
    </div>
  );
}
